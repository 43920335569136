import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import InPageNav from '../../components/InPageNav'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Section from '../../components/Section'
import SEO from '../../components/SEO'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  /* background-color: ${props => props.theme.colors.nearlyWhite}; */
`

const CenterContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    padding-bottom: 25px;
  }
`
const Calendar = ({ data }) => {
  const headerImage = data.allContentfulAsset.edges[0].node
  const calendarSubscriptionLink = ''

  return (
    <Layout>
      <SEO />

      <PageHeader image={headerImage} title="Calendar" />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/calendar/" activeClassName="active-page-link">
            Events
          </Link>
          <Link to="/calendar/subscribe/" activeClassName="active-page-link">
            Subscribe
          </Link>
        </InPageNav>
        <Container className="flex" wrap="true" afterStickyNav="true">
          <Section>
            <CenterContent>
              <p>
                Subscribe to sync all Maskcara Artist events with your personal
                calendar app.
              </p>
              <a
                className="btn--submit"
                href={calendarSubscriptionLink}
                target="_blank"
              >
                Subscribe
              </a>
            </CenterContent>
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Calendar
